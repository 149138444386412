import { configureStore } from "@reduxjs/toolkit";
import Auth_reducer from "./auth/AuthReducer";
import counterReducer from "./slices/counter";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

const persistConfig = {
  key: process.env.REACT_APP_STORE_KEY,
  storage,
};

const persistedReducer = persistReducer(persistConfig, Auth_reducer);

export const store = configureStore({
  reducer: { auth: persistedReducer, counter: counterReducer },
  devTools: process.env.REACT_APP_NODE_ENV !== "production",
  middleware: [thunk],
});

export const persistor = persistStore(store);

// export const store = configureStore({
//   reducer: {
//     counter: counterReducer,
//     auth: Auth_reducer,
//   },
// });
