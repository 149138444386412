import {
  Folder,
  Settings,
} from "react-feather";
const pagesSection = [

  {
    href: "/folders/folderslist",
    icon: Folder,
     title: "Folders",
  },

  {
    href: "/settings",
    icon: Settings,
     title: "Settings",
  },

];

const navItems = [
  {

    pages: pagesSection,
  },
];

export default navItems;
