import axios from "axios";
import { store } from "../../src/redux/store";
import {getToken} from "../helper";
import {SetRefreshToken} from "../../src/redux/auth/AuthAction";
export function injectstore() {
  axios.defaults.headers.Accept = 'application/json';


  const RequestHandler = (request) => {
    const state = store.getState();
    const { token } = state.auth;
    if (token) {
      request.headers.Authorization = 'Bearer ' + token;
    }
    return request;
  };

  const errorHandler = (error) => {
     console.log("error", error);

    if (error.response?.status === 401) {
      window.location.replace('/signout');
    }
    return Promise.reject(error
       (error.response && error.response.data) || 'Something went wrong///',
    );

  };
  axios.interceptors.request.use(
    (request) => RequestHandler(request),

  );
  axios.interceptors.response.use(

    (response) => {
      return response;
    },
    async(error) => { 
      
      if (error?.response?.status === 401) {
        error.message = error?.response?.data?.message;
          const originalRequest = error?.config;
          let firebaseRefreshToken = await getToken();
          store.dispatch(SetRefreshToken(firebaseRefreshToken));
          return axios(originalRequest);
      }
      else 
      return errorHandler(error) },
  )
}