import * as React from "react";
import styled, { withTheme } from "styled-components/macro";
import {
  Divider ,
} from "@mui/material";
import {
  Grid,
  AppBar as MuiAppBar,
  Toolbar,
} from "@mui/material";

import NavbarUserDropdown from "./NavbarUserDropdown";

const AppBar = styled(MuiAppBar)`
  background: #F5F8FF;
  color: ${(props) => props.theme.header.color};
`;


const Navbar = ({ onDrawerToggle }) => {
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
      <div>
        <Toolbar>
          <Grid container alignItems="center">
          
            <h3 variant="h2" display="inline" style={{ fontFamily:"Arial Black (sans-serif)",color:"black", paddingLeft:"20px" }} className="textResponsive">
            Hi Admin!
      </h3>
            <Grid item xs />
            <Grid item>
              <NavbarUserDropdown />
            </Grid>
            
          </Grid>
        </Toolbar>
        </div>
        <Divider my={6} style={{ marginBottom: "12px" }} />
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
