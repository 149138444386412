import React from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import { create } from "jss";
import { ThemeProvider } from "styled-components/macro";
import { PersistGate } from "redux-persist/lib/integration/react";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
// import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import { store, persistor } from "./redux/store";

import {injectstore} from "../src/global/header-token";
// import { style } from "@mui/system";
//  injectstore(store);

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

function App() {
  // const dispatch = useDispatch();
  injectstore(store);
  const content = useRoutes(routes);

  const { theme } = useTheme();

  return (
    <HelmetProvider>
      {/* <Helmet
        titleTemplate="%s | Link File Share App Admin"
        defaultTitle="Link File Share App Admin"
      /> */}
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <StylesProvider jss={jss}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={createTheme(theme)}>
                    {content}
                  </ThemeProvider>
              </StyledEngineProvider>
            </LocalizationProvider>
          </StylesProvider>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
