export const SET_FIREBASE_AUTHENTICATED = "SET_FIREBASE_AUTHENTICATED";
export const REMOVE_FIREBASE_AUTHENTICATED = "SET_FIREBASE_AUTHENTICATED";
export const DISPLAY_ERROR = "DISPLAY_ERROR";
export const SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN";

export const SetAuthentication = (token) => async (dispatch) => {
  dispatch({ type: SET_FIREBASE_AUTHENTICATED, payload: {isAuthenticated:true,token:token}});
};

export const SetRefreshToken = (token) => async (dispatch) => {
  dispatch({ type: SET_REFRESH_TOKEN, payload: {token:token}});
};
export const RemoveAuthentication = () => async (dispatch) => {

  dispatch({ type: REMOVE_FIREBASE_AUTHENTICATED, payload: {isAuthenticated:false,token:null} });
};

export const ErrorMessage = (errmsg) => async (dispatch) => {
  dispatch({ type: DISPLAY_ERROR, payload: errmsg });
};
