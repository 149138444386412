import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Button,
    Divider ,
} from "@mui/material";
function DeleteComponent({openDelForm, setOpenDelForm, DeleteRow, headingData, deleteReason, setDeleteReason}) {

    return (
        <Dialog
            open={openDelForm}
            onClose={() => {
                setOpenDelForm(false);
            }}
            fullWidth
        >
            <center><DialogTitle id="alert-dialog-title" style={{ fontWeight:"bold", fontSize:"16px", backgroundColor:"#edf4fc"}}>{headingData}</DialogTitle></center>
            <Divider my={6} style={{ marginBottom: "12px" }} />
            <DialogContent style={{ padding:0 }}>
            <center> <p >Are you sure you want to delete it?</p></center>
                  <center> <p style={{ marginBottom:"0px" }}>Reason to delete (Optional)</p></center>
    <center>  <form >
    <textarea  value={deleteReason}
          onChange={(event) => { setDeleteReason(event.target.value) }}
          rows={10}
          cols={45} />
    </form></center>  
            </DialogContent>
            <DialogActions  style={{  display:"flex", justifyContent:"center", marginBottom:"20px"}} >
                 
               <Button onClick={() => {
                        setOpenDelForm(false);
                        setDeleteReason("");
                    }}
                style={{  backgroundColor:"#edf4fc", fontSize:"15px", padding:"10px 50px 10px 50px" }}>
                    Cancel
                </Button>
              
                <Button
                style={{ backgroundColor:"#edf4fc", fontSize:"15px", padding:"10px 50px 10px 50px", color:"red" }}
                onClick={()=>{DeleteRow(deleteReason)}}
                >
                    Delete
                </Button>
              
            <br></br>
            </DialogActions>
           
        </Dialog>
        
    );
}
export default DeleteComponent;