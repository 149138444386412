import React from "react";
import auth from "../../config";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RemoveAuthentication } from "../../redux/auth/AuthAction";

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
const Signout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sign_Out = () => {
    auth.signOut();
    dispatch(RemoveAuthentication());
    navigate("/auth/sign-in");
  }

  return (
    <div>
      <Dialog
        open={true}
        onClose={sign_Out}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      
        <DialogTitle id="alert-dialog-title" color="error">Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="error">
            Your session has expired. Please login again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={sign_Out}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Signout;
