import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Icon from "@mui/icons-material";
import "../../../../src/TextAlign.css";
import * as Icons from "react-feather";
import * as genericServices from "../../../global/genericServices";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Loader from "../../../components/Loader";
import DeleteComponent from "./DeleteComponent";

import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Grid as MuiGrid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Divider,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);
const Grid = styled(MuiGrid)(spacing);
const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;
function FolderListTable({
  sort,
  order,
  searchText,
  updateDateTime,
  setSearchText,
  setText,
}) {
  const navigate = useNavigate();
  const [folderData, setFolderData] = useState([]);
  const [loaderHandler, setLoaderHandler] = useState(true);
  const [payloadToken, setPayloadToken] = useState("");
  const [openDelForm, setOpenDelForm] = useState(false);
  const [id, setId] = useState("");
  const [deleteReason, setDeleteReason] = useState("");
  const headingData = "Delete Folder";
  const [callHandler, setCallHandler] = useState(true);
  const [isForwardPagination, setIsForwardPagination] = useState(true);
  const [searchDialog, setSearchDialog] = useState(false);
  const [forwardButton, setForwardButton] = useState(false);
  const [backwardButton, setBackwardButton] = useState(false);
  const [lastForward, setLastForward] = useState(false);
  const [successDeleteAlert, setSuccessDeleteAlert] = useState(false);
  let newSearchText = "";

  async function collection_data(delKey) {
    let payload;
    if (delKey) {
      payload = {
        sortby: sort,
        orderby: order,
        paginationToken: payloadToken,
        delKey: delKey,
        isForwardPagination: isForwardPagination,
      };
    } else
      payload = {
        sortby: sort,
        orderby: order,
        paginationToken: payloadToken,
        isForwardPagination: isForwardPagination,
      };

    let response;
    if (searchText) {
      searchText = searchText.trim();
      if (searchText.includes("linkfileshare.com")) {
        newSearchText = searchText.split("/");
        searchText = newSearchText[3];
      }
      payload.linkSearch = searchText;
    }
    if (updateDateTime) {
      let unixDate = updateDateTime / 1000;
      payload.dateSearch = unixDate;
    }
    setLoaderHandler(true);
    response = await genericServices.post(`admin`, payload);
    if (response?.status === 201) {
      setBackwardButton(response.data.hasPrevious);
      setForwardButton(response.data.hasForward);
      if (response.data.data.length > 0) {
        setFolderData(response.data.data);
        setPayloadToken(response.data.paginationToken);
       
      } else {
        if (searchText) setSearchDialog(true);
      }
      if( response.data.hasForward === false || response.data.hasPrevious === false) {
      if(payload.paginationToken) setLastForward(true);
      }

      setLoaderHandler(false);
      setCallHandler(false);
    }
  }

  useEffect(() => {
    if (callHandler) collection_data();
  }, [callHandler]);

  useEffect(() => {
    setPayloadToken("");
    setLoaderHandler(true);
    setCallHandler(true);
  }, [sort, order, searchText, updateDateTime]);

  const DelelteRow = async (deleteReason) => {
    let response;
    let delKey = true;
    setLoaderHandler(true);
    if (searchText) {
      setSearchText("");
      setText("");
    }
    if (deleteReason) {
      response = await genericServices.Delete(`admin`, id, deleteReason);
    } else response = await genericServices.Delete(`admin`, id);
    setOpenDelForm(false);
    if (response?.status === 200) {
      collection_data(delKey);
      setSuccessDeleteAlert(true);
      setDeleteReason("");
      setId(null);
      setTimeout(()=>{setSuccessDeleteAlert(false);},[1500])
    } else {
      setLoaderHandler(false);
      let message = response?.data?.reason?.message ?? "Something went wrong";
      alert(message);
    }
  };
  function setDateTime(dateTime) {
    const newDate = new Date(dateTime * 1000).toLocaleString();
    const splitDate = newDate.split(",");
    return splitDate;
  }

  return (
    <>
      <Grid>
        <Card>
          <CardContent>
            <TableWrapper style={{ height: "430px", overflow: "auto" }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className="textAlign bold_heading">
                      Group Id
                    </TableCell>
                    <TableCell className="textAlign bold_heading">
                      Created Date
                    </TableCell>
                    <TableCell className="textAlign bold_heading">
                      No. of Files
                    </TableCell>
                    <TableCell className="textAlign bold_heading">
                      Total Size
                    </TableCell>
                    <TableCell className="textAlign bold_heading">
                      Expiry
                    </TableCell>
                    <TableCell className="textAlign bold_heading">
                      Platform
                    </TableCell>
                    <TableCell className="textAlign bold_heading">
                      OS Version
                    </TableCell>
                    <TableCell className="textAlign bold_heading">
                      App Version
                    </TableCell>
                    <TableCell className="textAlign bold_heading">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                {!folderData ? (
                  <Loader />
                ) : (
                  <TableBody>
                    {folderData.map((ud) => (
                      <TableRow id={ud.group_id} key={ud.group_id}>
                        <TableCell className="textAlign">
                          {ud.group_id}
                        </TableCell>
                        <TableCell className="textAlign">
                          {setDateTime(ud.created_at)}
                        </TableCell>
                        <TableCell className="textAlign">
                          {ud.no_of_files}
                        </TableCell>
                        <TableCell className="textAlign">{ud.size}</TableCell>
                        <TableCell className="textAlign">
                          {setDateTime(ud.expiry)}
                        </TableCell>
                        <TableCell className="textAlign">
                          {ud.platform?.toLowerCase()}
                        </TableCell>
                        <TableCell className="textAlign">
                          {ud.osVersion?.toLowerCase()}
                        </TableCell>
                        <TableCell className="textAlign">
                          {ud.appVersion}
                        </TableCell>
                        <TableCell className="textAlign">
                          <Icons.Eye
                            style={{ color: "#18631b" }}
                            className="pointer_margin5"
                            onClick={() => {
                              navigate(`/folders/files/${ud.group_id}`);
                            }}
                          />

                          <Icon.DeleteForever
                            style={{ color: "#8a2525" }}
                            className="pointer_margin5"
                            onClick={() => {
                              setOpenDelForm(true);
                              setId(ud.group_id);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableWrapper>
            <br />

            <div style={{ display: "flex", alignItems: "center" }}>
              {!searchText && backwardButton && (
                <Button
                  style={{ backgroundColor: "#F5F8FF", marginRight: "10px" }}
                  onClick={() => {
                    setIsForwardPagination(false);
                    setLoaderHandler(true);
                    setCallHandler(true);
                  }}
                >
                  <ArrowBackIosIcon
                    style={{ fontSize: "20px", color: "black" }}
                  />
                </Button>
              )}
              {!searchText && forwardButton && (
                <Button
                  style={{ backgroundColor: "#F5F8FF" }}
                  onClick={() => {
                    setIsForwardPagination(true);
                    setLoaderHandler(true);
                    setCallHandler(true);
                  }}
                >
                  <ArrowForwardIosIcon
                    style={{ fontSize: "20px", color: "black" }}
                  />
                </Button>
              )}

              {loaderHandler && (
                <CircularProgress size={25} style={{ marginLeft: "10px" }} />
              )}
            </div>

            <Dialog
              open={lastForward }
              onClose={() => {
                setLastForward(false);
              }}
              fullWidth
            >
              <center>
                <DialogTitle
                  id="alert-dialog-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    backgroundColor: "#edf4fc",
                  }}
                >
                  Alert!
                </DialogTitle>
              </center>
              <Divider my={6} style={{ marginBottom: "12px" }} />
              <DialogContent style={{ padding: 0 }}>
                <center>
                  {" "}
                  <p>No more records found.</p>
                </center>
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                <>
                  <Button
                    style={{
                      backgroundColor: "#edf4fc",
                      fontSize: "15px",
                      padding: "10px 50px 10px 50px",
                    }}
                    onClick={() => {
                      setLastForward(false);
                    }}
                  >
                    Cancel
                  </Button>
                </>
                <br></br>
              </DialogActions>
            </Dialog>

            <Dialog
              open={searchDialog}
              onClose={() => {
                setSearchDialog(false);
                setSearchText("");
                setText("");
              }}
              fullWidth
            >
              <center>
                <DialogTitle
                  id="alert-dialog-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    backgroundColor: "#edf4fc",
                  }}
                >
                  Alert!
                </DialogTitle>
              </center>
              <Divider my={6} style={{ marginBottom: "12px" }} />
              <DialogContent style={{ padding: 0 }}>
                <center>
                  {" "}
                  <p>No record found. Please try a different search term.</p>
                </center>
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                <>
                  <Button
                    style={{
                      backgroundColor: "#edf4fc",
                      fontSize: "15px",
                      padding: "10px 50px 10px 50px",
                    }}
                    onClick={() => {
                      setText("");
                      setSearchText("");
                      setSearchDialog(false);
                    }}
                  >
                    Cancel
                  </Button>
                </>
                <br></br>
              </DialogActions>
            </Dialog>

            <Dialog
              open={successDeleteAlert} 
            >
            <DialogContent style={{ padding: "20px 20px 20px 20px" }}>
             <center>  <Icon.CheckCircleOutline style={{ height:"40px", width:"40px", color:"darkgreen" }}></Icon.CheckCircleOutline>
                  <p>Deleted Successfully</p> </center>
               </DialogContent>
            </Dialog>
            
            <DeleteComponent
              headingData={headingData}
              openDelForm={openDelForm}
              setOpenDelForm={setOpenDelForm}
              DeleteRow={DelelteRow}
              loaderHandler={loaderHandler}
              deleteReason={deleteReason}
              setDeleteReason={setDeleteReason}
            />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
export default FolderListTable;
