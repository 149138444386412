import React from "react";

import { Navigate } from "react-router-dom";
// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size
import SignOut from "../src/components/auth/signout";
// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
// Guards
import AuthGuard from "./components/guards/AuthGuard";
// Auth components


import SignIn from "./pages/auth/SignIn";
import FilesList  from "./pages/pages/Folders/FilesList";
import SettingsList from "./pages/pages/Settings/PackageSettings";
 import FoldersList from "./pages/pages/Folders/FolderSearch";

const routes = [

  {
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "folders",
        
        children: [
          {
            path: "foldersList",
            element: <FoldersList />,
          },
          {
            path: "files/:id",
            element: <FilesList />,
          },
        ]
        },
      
      {
        path: "settings",
        
        children: [
          {
            path: "",
            element: <SettingsList />,
          },

        ],
      },

      {
        path: "signout",
        element: <SignOut />,

      },
   
      {
            path: "",
            element: <Navigate to='/folders/folderslist' />,
          },
    ],
  },




  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
    ],
  },
];

export default routes;
