import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";
import * as Icon from "@mui/icons-material";
import "../../../../src/TextAlign.css";
import * as genericServices from "../../../global/genericServices";
import Loader from "../../../components/Loader";
import {
    Typography ,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Grid as MuiGrid,
  Table,
  TextField,
  TableBody,
  TableCell,
  TableHead,
  TableRow,

} from "@mui/material";
import { spacing } from "@mui/system";
const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Grid = styled(MuiGrid)(spacing);



const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;
function SettingsListTable() {
  const [packagesData, setPackagesData] = useState([]);
const [editSettings, setEditSettings] = useState("");
  //update states
  const [updateNoOfFiles, setUpdateNoOfFiles] = useState("");
  const [updateSize, setUpdateSize] = useState("");
  const [updateErrorMsg, setUpdateErrorMsg] = useState("");
  const [loaderHandler, setLoaderHandler] = useState(true);

  const packages_data = useCallback(async () => {

    let response = await genericServices.get(`package`);
      if (response?.status === 200) {
        setPackagesData(response.data);
      }
      else {
        let message = response?.data?.reason?.message ?? 'Something went wrong';
        console.log("ERROR===> ", message);
      }
      setLoaderHandler(false);
},[setLoaderHandler]);

  useEffect(() => {
    packages_data();
  }, [packages_data]);

 function extractSizeInNumber(sizeInString) {
  const splitArray = sizeInString.split(" ");
  const sizeInNumber = splitArray[0]; 
  return sizeInNumber;
 }

  const UpdateRow = async () => {
    let payload = "";
    let size;
    if(typeof updateSize === "string") size = parseInt(updateSize);
    else size = updateSize;
   if ( updateNoOfFiles === "" || updateSize === "" ) {
      setUpdateErrorMsg("Fields cannot be null");
    }
        else {
            payload = { noOfFiles: parseInt(updateNoOfFiles),size: size, fieldSize: 2 }
      let response = await genericServices.update(`package`,'',payload);
      if (response.status === 200) {
        setEditSettings("");
        setUpdateErrorMsg("");
        packages_data();
      } else {
        let message = response?.data?.reason?.message ?? 'Something went wrong';
        setUpdateErrorMsg(message);
        setEditSettings("");
      // }
    }
  }
  }
return(<><Grid>
{loaderHandler ? (<Loader />) :
      <Card mb={6}>
        <CardContent>

          <TableWrapper>
            <Table>
            <TableHead>
              <TableRow>
              
                      <TableCell className="textAlign bold_heading">Type</TableCell>
                      <TableCell className="textAlign bold_heading">No. of Files</TableCell>
                      <TableCell className="textAlign bold_heading">Size</TableCell>
                      <TableCell className="textAlign bold_heading">Size In Bytes</TableCell>
                      <TableCell className="textAlign bold_heading">Actions</TableCell>
                      
              </TableRow>
              </TableHead>
              <TableBody>

                {packagesData.map((pd) => (
            
                    (pd.type === editSettings) ?
                    <TableRow key={pd.sizeInBytes}>
                    <TableCell className="textAlign bold_heading">{pd.type}</TableCell>
                    <TableCell className="textAlign"><TextField
                              type="number"
                              name="noOfFiles"
                              label="No. of Files"
                              value={updateNoOfFiles}
                              onChange={(event) => { setUpdateNoOfFiles(event.target.value) }}
                              fullWidth

                              my={2}
                            /></TableCell>
                                <TableCell className="textAlign" style={{ display:"flex" }}><TextField
                              type="text"
                              name="size"
                              label="Size"
                              value={updateSize}
                              onChange={(event) => { setUpdateSize(event.target.value) }}
                              my={2}
                              fullWidth
                            /><div style={{  marginTop:"auto", marginBottom:"auto", fontWeight:"bold", fontSize:"15px"}}>GB</div></TableCell>
                            <TableCell className="textAlign bold_heading">{pd.sizeInBytes}</TableCell>
                            <TableCell className="textAlign">
                        <Icon.Save style={{ color:"#154596" }}
                          className="pointer_margin5"
                          onClick={() => {
                            UpdateRow();
                          }} />
                      </TableCell>
                </TableRow>
                    :
                    <TableRow key={pd.sizeInBytes}>
                    <TableCell className="textAlign ">{pd.type}</TableCell>
                    <TableCell className="textAlign">{pd.noOfFiles}</TableCell>
                    <TableCell className="textAlign">{pd.size}</TableCell>
                      <TableCell className="textAlign">{pd.sizeInBytes}</TableCell>
                      <TableCell className="textAlign">
                        <Icon.Edit style={{ color:"#154596" }}
                          className="pointer_margin5"
                          onClick={() => {
                            setEditSettings(pd.type);
                            setUpdateNoOfFiles(pd.noOfFiles);
                            setUpdateSize(extractSizeInNumber(pd.size));
                            

                          }} />
                      </TableCell>
                    </TableRow>

                ))}
              </TableBody>
            </Table>
          </TableWrapper>
          <Dialog
            open={updateErrorMsg ? true : false}
            onClose={() => { setUpdateErrorMsg("") }}

          >
            <DialogTitle id="alert-dialog-title">Error in Update</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {updateErrorMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => { setUpdateErrorMsg("") }}>Ok</Button>
            </DialogActions>
          </Dialog>
        
        </CardContent>
      </Card>
    
}</Grid></>);
}
function SettingsList() {
    return (
      <>
        <div style={{ display: "flex" }}>
          <Typography variant="h3"  display="inline">
            Settings
          </Typography>
  <br/><br/>
        </div>

     
        <Grid item xs={12} lg={12} xl={12}>
          <SettingsListTable/>
        </Grid>
      </>
    );
  }
export default SettingsList;