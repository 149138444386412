 import auth from "./config";

export const getToken = () => {
  return new Promise((resolve) => {
    auth.onAuthStateChanged(function(user) {
      if (user) {
        user
          .getIdTokenResult() 
          .then(function(idTokenResult) {
            if(idTokenResult.claims['super-admin']=== true)
              resolve(idTokenResult.token);
              else resolve("");
            })
          .catch(function() {
            resolve("");
          });
      } else {
        resolve("");
      }
    });
  });
};

