import * as React from "react";
import styled from "styled-components/macro";

import {
  Grid
} from "@mui/material";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(0.25)}
    ${(props) => props.theme.spacing(4)};
  background-color:white;
  position: relative;
`;


function Footer() {
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Grid
          sx={{ display: { xs: "none", md: "block" } }}
          container
          item
          xs={12}
          md={6}
        >
          <p
            style={{
              fontWeight: "bold",
              fontSize: "15px",
              display: "inline-block",
              color:"black",
            }}
          >
            Copyright &copy; 2023 Whizpool.
          </p>
          <span style={{ fontSize: "15px", display: "inline-block",color:"black" }}>
            All Rights reserved.
          </span>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
