import React, { useState } from "react";
import {  Typography as MuiTypography
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "styled-components/macro";
import "../../../../src/TextAlign.css";
import FoldersList from "./FoldersList";
import AdapterDateFns from '@date-io/date-fns';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import {
  MenuItem,
  TextField,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";

import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';

const Typography = styled(MuiTypography)(spacing);

function UsersList() {
  const [searchtext, setSearchText] = useState("");
  const [text, setText] = useState("");
  const [updateDateTime, setUpdateDateTime] = useState('');
  const [value, setValue] = React.useState(Date());
  const [sort, setSort] = useState("bytes");
  const [order, setOrder] = useState("asc");
  function keyPress(e){
    if(e.keyCode === 13){
       setSearchText(text)
    }
 }

  return (
    <>
   
      <div style={{ display: "flex" }}>
        <Typography variant="h3"  display="inline">
          Folders
        </Typography>
        <FormControl style={{ width:"150px", marginLeft: "auto", marginRight: "50px"  }}>
  <InputLabel id="demo-simple-select-label">Sort by</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={sort==="no_of_files" ? "No. of files" :  sort==="bytes" ? "Size" : sort==="created_at" ? "Created Date" : sort==="expiry" && "Expiry" }
    label="Sort By"
  >
       <MenuItem value={"Size"}  onClick={()=>{setSort("bytes"); }} >Size</MenuItem>
            <MenuItem value={"Expiry"}  onClick={()=>{setSort("expiry");}}>Expiry</MenuItem>
            <MenuItem value={"No. of files"} onClick={()=>{setSort("no_of_files");}}>Number of Files</MenuItem>
            <MenuItem value={"Created Date"} onClick={()=>{setSort("created_at");}}>Created Date</MenuItem>
  </Select> 
 </FormControl>

 <FormControl style={{ width:"150px",  marginRight: "50px" }}>
  <InputLabel id="select-label">Order by</InputLabel>
  <Select
    labelId="select-label"
    id="simple-select"
    value={order==="asc" ? "Ascending" :  order==="desc" && "Descending"}
    label="Order By"
  >
    <MenuItem value={"Ascending"} onClick={()=>{setOrder('asc');}}>Ascending</MenuItem>
            <MenuItem  value={"Descending" }  onClick={()=>{setOrder('desc');}}>Descending</MenuItem>
         </Select> 
 </FormControl>



          <LocalizationProvider dateAdapter={AdapterDateFns} >
            <MobileDateTimePicker 
            format="mm-dd-yyyy"
            
              onChange={(e) => {
                setUpdateDateTime(new Date(e).getTime());
                setValue(e);
          
              }} label= "Select date & time"
              value={value}
              renderInput={(params) => <TextField onKeyDown={ (e) => {
    e.preventDefault();
 }
} {...params} />}

            />
          </LocalizationProvider>

          <FormControl sx={{ width: '25ch' }} style={{ marginLeft:"50px",  marginRight: "10px" }} variant="outlined">
          {/* <InputLabel sx={{ transform: 'translate(14px, -6px) scale(0.75)' }} htmlFor="outlined-adornment-search">Search by link / url</InputLabel> */}
          <InputLabel id="select-label">Search link </InputLabel>
          <OutlinedInput
            id="outlined-adornment-search"
            type="text"
            value={text}
               onChange={(e) => { 
                    setText(e.target.value); }}
                    onKeyDown={keyPress}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon className="hover-pointer" onClick={() => { 
                  setSearchText(text);
                    }}
                    />
                
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
          
      </div>
      <br/>
        <FoldersList
        searchText={searchtext}
        sort={sort}
        order={order}
        updateDateTime={updateDateTime}
        setSearchText={setSearchText}
        setText={setText}
         />
    </>
  );
}
export default UsersList;