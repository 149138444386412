import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import "../../../src/TextAlign.css";
import * as Yup from "yup";
import { Formik } from "formik";
import {getToken} from "../../../src/helper";
import {
  Button,
  TextField as MuiTextField,
  Alert as MuiAlert,
} from "@mui/material";
import { spacing } from "@mui/system";
import { SetAuthentication } from "../../redux/auth/AuthAction";
import auth from "../../config";
import { useDispatch } from "react-redux";



const TextField = styled(MuiTextField)(spacing);
const Alert = styled(MuiAlert)(spacing);

function SignIn() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [err, setErr] = useState("");

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()

          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting, }) => {
        setErr("");
        try{
          await auth.signInWithEmailAndPassword(
        
            values.email,
            values.password,
          
          );
          
          let token = await getToken();
     if(token !== "") {
      dispatch(SetAuthentication(token));
          navigate("/folders/folderslist");
     }
     else {
      const message = "Something went wrong";
          setErr(" Invalid email or password");
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
     }
        }
        catch(error){
          console.log(error);
          const message = error.message || "Something went wrong";
          setErr(" Invalid email or password");
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          {err && (
            <Alert mb={4} severity="error">
              Error<strong>{err}</strong>
            </Alert>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isSubmitting}
            className="Add_Buttons_color"
          >
            Sign in
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
