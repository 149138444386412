import * as Icon from "@mui/icons-material";
import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import "../../../../src/TextAlign.css";
import * as genericServices from "../../../global/genericServices";
import Loader from "../../../components/Loader";
import DeleteComponent from "./DeleteComponent";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  Card as MuiCard,
  CardContent,  Grid as MuiGrid, Link, Table, TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Grid = styled(MuiGrid)(spacing);



const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;
function FilesListTable() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [delId, setDelId]=useState("");
    const [loaderHandler, setLoaderHandler] = useState(true);
    const [openDelForm, setOpenDelForm] = useState(false);
    const [deleteReason, setDeleteReason] = useState("");
    const headingData = "Delete File";
    const [filesData, setFilesData] = useState([]); 
    const [successDeleteAlert, setSuccessDeleteAlert] = useState(false);
    const files_data = useCallback(async () => {

    let response = await genericServices.get(`admin/files?id=${id}`);
      if (response?.status === 200) {
      if(response.data.length>0) setFilesData(response.data);
      else {navigate("/folders/folderslist"); }
        
      }
      else {
        let message = response?.data?.reason?.message ?? 'Something went wrong';
        console.log("ERROR===> ", message);
      }
      setLoaderHandler(false);
},[id, setLoaderHandler,navigate]);

  useEffect(() => {
    files_data();
  }, [files_data]);

  const DelelteRow = async (deleteReason) => {
    let response;
    if(deleteReason){
      response = await genericServices.Delete(`admin`, delId, deleteReason); 
    } else response = await genericServices.Delete(`admin`, delId); 

    if (response?.status === 200) {
      files_data();
      setOpenDelForm(false);  
      setDeleteReason("");
      setSuccessDeleteAlert(true);
      setTimeout(()=>{setSuccessDeleteAlert(false);},[1500]);
    }

    else {
      setLoaderHandler(false);
      let message = response?.data?.reason?.message ?? 'Something went wrong';
      alert(message);
    }

  };

return(<><Grid>
   {loaderHandler ? (<Loader />) :
      <Card>
   
        <CardContent>
       
          <TableWrapper style={{ height:"450px",overflow:"auto" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                <TableCell className="textAlign bold_heading">Unique Id</TableCell>
                  <TableCell className="textAlign bold_heading">Size</TableCell>
                  <TableCell className="textAlign bold_heading">Type</TableCell>
                  <TableCell className="textAlign bold_heading">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {filesData.map((fd) => (
                    <TableRow key={fd.uniqueId}>
                    <TableCell className="textAlign">{fd.uniqueId}</TableCell>
                    <TableCell className="textAlign">{fd.size}</TableCell>
                      <TableCell className="textAlign">{fd.type}</TableCell>
                      <TableCell className="textAlign">
                        <Icon.DeleteForever style={{ color:"#8a2525" }}
                          className="pointer_margin5"
                          onClick={() => {
                            setOpenDelForm(true);
                            setDelId(fd.uniqueId);
                          }} />
                      </TableCell>
                    </TableRow>

                ))}
              </TableBody>
            </Table>
          </TableWrapper> 
          <Dialog
              open={successDeleteAlert} 
            >
            <DialogContent style={{ padding: "20px 20px 20px 20px" }}>
             <center>  <Icon.CheckCircleOutline style={{ height:"40px", width:"40px", color:"darkgreen" }}></Icon.CheckCircleOutline>
                  <p>Deleted Successfully</p> </center>
               </DialogContent>
            </Dialog>
          <DeleteComponent
            headingData={headingData}
            openDelForm={openDelForm}
            setOpenDelForm={setOpenDelForm}
            DeleteRow={DelelteRow}
            loaderHandler={loaderHandler}
            deleteReason={deleteReason}
            setDeleteReason={setDeleteReason}
          />
        
        </CardContent>
      
      </Card>
      }
  </Grid></>);
}
function FilesList() {
    return (
      <>
        <div style={{ display: "flex" }}>
          <Typography variant="h3"  display="inline">
            Files
          </Typography>
  
        </div>
  
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/folders/foldersList">
          Folders
        </Link>
          <Typography>Files</Typography>
        </Breadcrumbs>
        <br/>
        <Grid item xs={12} lg={12} xl={12}>
          <FilesListTable/>
        </Grid>
      </>
    );
  }
export default FilesList;