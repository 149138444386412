import { SET_FIREBASE_AUTHENTICATED,REMOVE_FIREBASE_AUTHENTICATED, DISPLAY_ERROR, SET_REFRESH_TOKEN} from "./AuthAction";

const initialState = {
  token:"",
  isAuthenticated: false,
  errmsg: "Wrong Credentials",
 
};

const Auth_reducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_FIREBASE_AUTHENTICATED: {
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        token: action.payload.token,
      };
    }
    case REMOVE_FIREBASE_AUTHENTICATED: {
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        token: action.payload.token
      };
    }
    case SET_REFRESH_TOKEN: {
      return {
        ...state,
        token: action.payload.token
      };
    }
    case DISPLAY_ERROR: {
      return {
        ...state,
      };
    }
   
    default: {
      return { ...state };
    }
  }
};

export default Auth_reducer;
