import axios from "axios";

export function axiosCatchFunction(error) {
  return error.response;
}

export async function post(apiName, data = {}) {
  const url = `${process.env.REACT_APP_BASE_URL}${apiName}`;
  return axios({
    url: url,
    method: "POST",
    data,
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(axiosCatchFunction);
}

export async function get(apiName) {
  const url = `${process.env.REACT_APP_BASE_URL}${apiName}`;

  return axios({
    url: url,
    method: "GET",
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(axiosCatchFunction);
}

export async function Delete(apiName, id, payload) {
  let url;
  if(payload !== "") url = `${process.env.REACT_APP_BASE_URL}${apiName}/${id}?report=${payload}`;
 else  url = `${process.env.REACT_APP_BASE_URL}${apiName}/${id}`;

  return axios
    .delete(url)
    .then(function (response) {
      return response;
    })
    .catch(axiosCatchFunction);
}

export async function bulkDelete(apiName, data) {
  const url = `${process.env.REACT_APP_BASE_URL}${apiName}`;
  return axios
    .delete(url, { data })
    .then(function (response) {
      return response;
    })
    .catch(axiosCatchFunction);
}

export async function update(apiName, id, data) {
  let url="";
  if(id==='') 
  url = `${process.env.REACT_APP_BASE_URL}${apiName}/${id}`;
  else 
  url = `${process.env.REACT_APP_BASE_URL}${apiName}`;


  return axios
    .patch(url, data)
    .then(function (response) {
      return response;
    })
    .catch(axiosCatchFunction);
}

export async function FakeGet(apiName) {
  const url = `https://jsonplaceholder.typicode.com/${apiName}`;

  return axios({
    url: url,
    method: "GET",
    crossDomain: true,
  })
    .then(function (response) {
      return response;
    })
    .catch(axiosCatchFunction);
}
