import React from "react";
import styled from "styled-components/macro";
import logo from '../pages/images/unnamed.png';
import { Paper, Typography } from "@mui/material";

import SignInComponent from "../../components/auth/SignIn";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignIn() {
  return (
    <React.Fragment>
<img style={{ width:"80px", height:"80px" }} src={logo} alt="icon"/><br/>
      <Typography
        component="h1"
        variant="h1"
        align="center"
      >
        Link File Share Admin Panel
        <br/><br/>
      </Typography>
      <Wrapper>
        <Typography
          component="h2"
          variant="body1"
          align="center"
           style={{ color: "black", fontSize: "16px", fontWeight: "bold" }}
        >
          Sign in to start your session
        </Typography>
        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
