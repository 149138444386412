import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./App";

// import { ThemeProvider } from "./contexts/ThemeContext";
// import {injectstore} from "./../src/global/header-token";
//  injectstore(store);
ReactDOM.render(
  <BrowserRouter>
    {/* <ThemeProvider> */}
  
       <App />
       
    {/* </ThemeProvider> */}
  </BrowserRouter>,
  document.getElementById("root")
);

// import "react-app-polyfill/stable";
// import React from "react";
// import ReactDOM from "react-dom";
// import { BrowserRouter } from "react-router-dom";
// import reportWebVitals from "./utils/reportWebVitals";
// import App from "./App";
// import "./mocks";
// import { ThemeProvider } from "./contexts/ThemeContext";
// import { store } from "./redux/store";
// // import {injectstore} from "./global/header-token";
// // injectstore(store);
// ReactDOM.render(
//   <BrowserRouter>
//     <ThemeProvider>
//       <App />
//     </ThemeProvider>
//   </BrowserRouter>,
//   document.getElementById("root")
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

